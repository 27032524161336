import axios from 'axios';
import { validateUserSession } from './components/UserContext';
import { magic } from './components/UserContext';

// Determine the environment
const environment = process.env.REACT_APP_ENV || 'production';

// Export the getBaseURL function so it can be used elsewhere
export const getBaseURL = () => {
  if (window.location.hostname === 'localhost') {
    console.log('Localhost detected, using localhost:8081');
    // CHANGE THIS BACK TO USE LOCALHOST:8000 after testing
    return 'http://localhost:8081'; 
    // return 'https://staging.proprietyrealty.com';
  }

  if (environment === 'staging') {
    console.log('Staging environment detected');
    return 'https://staging.proprietyrealty.com';
  }

  console.log('Production environment detected');
  // for production deployment, use the api.proprietyrealty.com endpoint
  // THIS WILL NEED TO CHANGE FOR THE NEW SERVER
  // return 'https://api.proprietyrealty.com';
  return 'https://server.proprietyrealty.com';
};

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  timeout: 900000, // 900 seconds
  headers: {
    'Content-Type': 'application/json',
    // You may have other default headers here
  },
  referrerPolicy: {
    policy: 'strict-origin-when-cross-origin',
  },
});

// Add request interceptor for auth validation -- you can also add versioning check here.
axiosInstance.interceptors.request.use(
  (config) => {
    // Skip validation for certain endpoints if needed
    if (config.url?.includes('/auth') || config.url?.includes('/signin')) {
      return config;
    }

    const userInfo = localStorage.getItem('userInfo');
    if (!userInfo) {
      return Promise.reject(new Error('No session found'));
    }

    try {
      const parsedUserInfo = JSON.parse(userInfo);
      if (!validateUserSession(parsedUserInfo)) {
        console.log('Invalid or expired session');
        return Promise.reject(new Error('Invalid or expired session'));
      }
      console.log('User info is valid, session authenticated');
      return config;
    } catch (error) {
      return Promise.reject(new Error('Invalid session data'));
    }
  },
  (error) => Promise.reject(error)
);

// Update response interceptor to handle errors without recursive auth checks
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isAuthError = 
      error.message === 'Invalid or expired session' ||
      error.message === 'No session found' ||
      error.message === 'Invalid session data';

    if (isAuthError && window.location.pathname !== '/signin') {
      localStorage.removeItem('userInfo');
      if (magic.user) {
        try {
          await magic.user.logout();
        } catch (logoutError) {
          console.error('Error during logout:', logoutError);
        }
      }
      window.location.href = '/signin';
    }
    return Promise.reject(error);
  }
);

// This should be made to Only retry in the instances where the error is
// basically if a network error, this is a sql broken pipe internal error I believe
// Failed to load resource: the server responded with a status of 500 (INTERNAL SERVER ERROR)
// "(pymysql.err.OperationalError) (2006, "MySQL server has gone away (TimeoutError(110, 'Connection timed out'))")
// [SQL: 
//   SELECT EvalDate
//   FROM Calcs.RAR
//   WHERE FirstFivePID = %(muni_code_query)s AND Year = %(tax_year)s
// ]
// [parameters: {'muni_code_query': 'S0900', 'tax_year': '2023'}]
// (Background on this error at: https://sqlalche.me/e/20/e3q8)"
// Add retry interceptor

// axiosInstance.interceptors.response.use(
//   (response) => response, // Return the response if no error
//   async (error) => {
//     const config = error.config;

//     // If `config` doesn't exist or has no retries, reject the error
//     if (!config || config.__retryCount >= 2) {
//       return Promise.reject(error);
//     }

//     // Initialize the retry count if it doesn't exist
//     config.__retryCount = config.__retryCount || 0;

//     // Increment the retry count
//     config.__retryCount += 1;

//     console.warn(`Retrying request (${config.__retryCount}/2)...`);

//     // Wait for a specified amount of time before retrying
//     await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

//     // Retry the request
//     return axiosInstance(config);
//   }
// );

export default axiosInstance;
