import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { darken, lighten } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import DBConfirmModal from '../../common/DBConfirmModal';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import DataGridContainer from './DataGridContainer';
import {GetCompPhoto} from '../../common/helper_functions'
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axiosInstance from '../../axiosConfig';
import LanguageIcon from '@mui/icons-material/Language';
import {handleCopy} from '../../common/helper_functions';
import { usePersistedDataStore } from '../store.js';

// Memoize the getBackgroundColor function?
const getBackgroundColor = (color, theme, coefficient) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles('light', {
    backgroundColor: lighten(color, coefficient),
  }),
});

// Updated this so its now its own component which gets rendered over the map.
export const PropertyInfoTooltip = ({ address, parcelId, SDName, filteredHeaders, MLSNumber, SalePrice, SaleDate, SDCode, town, zip, subject = false, RepId, remarks }) => {
  const addressCopy = `${address}, ${town}, NY ${zip}`;
  const [imgLink, setImgLink] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to manage the popover anchor element
  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State for menu anchor
  const [loading, setLoading] = useState(false); // State to manage loading status

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element to the clicked icon
    setLoading(true); // Set loading to true when opening the popover

    const link = await GetCompPhoto(parcelId); // Fetch the image link
    setImgLink(link); // Set the fetched image link
    setLoading(false); // Set loading to false after fetching
  };

  const handlePopoverClose = () => {
    setAnchorEl(null); // Close the popover
    setImgLink(null); // Reset imgLink when closing the popover
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget); // Set menu anchor to open the menu
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null); // Close the menu
  };

  // NEED TO WRITE THIS BACKEND ROUTE
  const flagBadSale = () => {
    console.log('Flagging bad sale - need to write this route on BE still.'); // Placeholder for actual logic
    // console.log(parcelId); // hit a backend route to flag bad sale
    // Hit backend route with axiosInstance to flag bad sale
    const saleObj = {
      parcelId,
      SalePrice,
      SaleDate,
    };
    console.log(SaleDate)

    const nalSaleResponse = axiosInstance.post('/flag-bad-sale', { saleObj })
      .then((response) => {
        console.log('Flagged bad sale:', response.data);
        toast.success('Successfully flagged bad sale!', {
          position: 'top-right',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error flagging bad sale:', error);
        toast.error('Failed to flag bad sale!', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    );
  };

  const open = Boolean(anchorEl); // Check if popover is open
  const menuOpen = Boolean(menuAnchorEl); // Check if menu is open

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }} className="table-cell-truncate">
      {/* Truncated address text */}
      <span
        onClick={handlePopoverOpen}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        className="flex-grow"
      >
        {address}
      </span>

      {/* Popover component */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '10px', position: 'relative' }}>
          {/* More Icon - identify things about this sale.*/}
          <IconButton
            onClick={handleMenuOpen}
            style={{ position: 'absolute', top: '5px', right: '45px' }}
          >
            <MoreVertIcon />
          </IconButton>
          {/* Menu for More Icon */}
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={flagBadSale}>Flag Bad Sale</MenuItem>
            <MenuItem disabled>Option 1</MenuItem>
            <MenuItem disabled>Option 2</MenuItem>
          </Menu>

          {/* Close icon */}
          <IconButton
            onClick={handlePopoverClose}
            style={{ position: 'absolute', top: '5px', right: '5px' }} // Position the close button
          >
            <CloseIcon />
          </IconButton>
          <div className='flex justify-between'>
            <span className="text-base">{address}
              {/* Info icon to trigger popover */}
              {/* <IconButton onClick={() => handleCopy(addressCopy, 'Address')} */}
              <IconButton
                  onClick={() => window.open(`https://www.google.com/search?q=${encodeURIComponent(addressCopy)}`, '_blank')}
                  className="ml-1 p-1"
                >

                <LanguageIcon
                  style={{ cursor: 'pointer', marginLeft: '2px', marginBottom: '2px', fontSize: '14px' }}
                />
              </IconButton>

            </span>
          </div>
          <div>
            {subject && (
              <div className='flex flex-col'>
                <span className="text-xs">
                  {subject.RepId !== '' ? subject.Name + ` - REPID: ${subject.RepId}` : subject.Name}
                </span>

                {subject.ScarIndex && (
                  <span className="text-xs cursor-pointer" onClick={() => handleCopy(subject.ScarIndex, 'Scar Index')}>
                    SCAR ID: {subject.ScarIndex}
                    <ContentCopyIcon
                      style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '4px', fontSize: '12px' }}
                    />
                  </span>
                )}
              </div>
            )}

            <>
            <span className="text-xs font-bold mr-1">
              Parcel Id:
              </span>
            <span className="text-xs cursor-pointer" onClick={() => handleCopy(parcelId, 'PID')}>
              {parcelId}
            </span>

            <ContentCopyIcon
              onClick={() => handleCopy(parcelId, 'PID')}
              style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '4px', fontSize: '15px' }}
            />
            </>
          </div>

          {MLSNumber !=='None'&& (
            <>
                <span className="text-xs font-bold mr-1">
                                  MLS Number:
              </span>
                <span className="text-xs cursor-pointer">
                   {MLSNumber}
                  <ContentCopyIcon
                    onClick={() => handleCopy(MLSNumber, 'MLS Number')}
                    style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '4px', fontSize: '15px' }}
                  />
                </span>
                </>
              )}            
          <div>
            <span className="text-xs font-bold mr-1">
              School District:
            </span>
            <span className="text-xs">
              {SDName} ({SDCode})
            </span>
          </div>

          <div className='max-w-96'>
            <span className="text-xs font-bold mr-1">
              remarks:
            </span>
            <span className="text-xs">
              {remarks}
            </span>
          </div>
          {/* Conditionally render the image only if the popover is open */}
          {open && (
            <img 
              src={loading ? "https://placehold.co/400x350/jpg" : imgLink} 
              alt="Property" 
              style={{ maxWidth: '100%', marginTop: '10px' }} 
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

// const areEqual = (prevProps, nextProps) => {
//   return isEqual(prevProps.originalRows, nextProps.originalRows) &&
//          isEqual(prevProps.subject, nextProps.subject) &&
//          isEqual(prevProps.tableProps, nextProps.tableProps) &&
//          isEqual(prevProps.rowUpdate, nextProps.rowUpdate) &&
//          isEqual(prevProps.savedCompPids, nextProps.savedCompPids) &&
//          isEqual(prevProps.handleSaveComps, nextProps.handleSaveComps) &&
//          isEqual(prevProps.caseNotesChanged, nextProps.caseNotesChanged) &&
//          isEqual(prevProps.isSaving, nextProps.isSaving)
//         //  
//         //  isEqual(prevProps.caseNotesChanged, nextProps.caseNotesChanged);
// };

function MuiTable({ headers, view, selectedComps, addCompNotInResponseCallback, filteredHeaders, handleSetPropertyInfo, handleColumnVisibilityChange, memoizedColumnVisibilityModel, isSaving, setIsSaving, unsavedChangesRef, handleProcessDBUpdate, handleCaseNotesCleanup, caseNotesChanged, originalRows, updateSelectedComps, subject = false, tableProps, savedCompPids, handleSaveComps }) {
  const startingRows = cloneDeep(originalRows);
  console.log('originalRows', originalRows)
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  // Use the memoized queryParams object to calculate 'comp'
  const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
  // This entire "user update" state is redundant, we have this in selectedComps int he parent component.
  // Working around this for now by also updating the parent state w/ callback.
  // const [userUpdate, updateSelectedComps] = useState(false);
  const [reordering, setReordering] = useState(false);
  const reOrderingRef = useRef(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userReordered, setUserReordered] = useState(false);
  // why are you memoizing this? not sure this does anything of value.
  // console.log(isSaving)

  const [toolbarKey, setToolbarKey] = useState(1); // is this still relevant to us now that toolbar isn't memoized?
  // Test inventory update if you move this up to casereview

// console.log(forceUpdate)
  // Updated this to be based on your actual original rows instead of deep clone (for inventory update purposes)
  const { rows, pinnedRows } = useMemo(() => {
    
    // Comment in to turn off row pinning.
    // return {
    //   rows: startingRows,
    //   pinnedRows: { top: [], bottom: [] },
    // };
    if (view === 'regular') {
      const rowsData = [...originalRows]; // Just return all rows as is
      return {
        rows: rowsData,
        pinnedRows: { top: [], bottom: [] }, // Empty pinnedRows for 'regular' view
      };
    }
    if (!startingRows || !savedCompPids) return { rows: [], pinnedRows: { top: [], bottom: [] } };
    const savedCompPidsSet = new Set(savedCompPids); // Optimized lookup using Set
    const updatedRowsSet = selectedComps && selectedComps.length > 0 ? new Set(selectedComps) : new Set(); // Optimized lookup using Set
    const rowsData = [];
    const pinnedRowsData = { top: [], bottom: [] }; // Ensure that top and bottom are arrays
  
    // Use savedCompPids or selectedComps to dictate order of pinned rows
    const pinningSource = selectedComps ? selectedComps : savedCompPids;
  
    // Loop through pinningSource in order and push to pinnedRows
    pinningSource.forEach((pid) => {
      const row = originalRows.find((r) => r.parcel_id === pid);
      if (row) {
        row.selected = true;
        pinnedRowsData.top.push(row); // Adding to the top in the order they appear in the set
      }
    });
  
    // Add remaining rows that aren't pinned to rowsData
    originalRows.forEach((row) => {
      if (!pinningSource.includes(row.parcel_id)) {
        rowsData.push(row);
      }
    });
  
    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData, // Ensure pinnedRowsData contains valid arrays
    };
  }, [originalRows, savedCompPids, selectedComps]);

  // comparison function if the user has chosen comps other than the saved ones.
  const isSame = useMemo(() => {
    if (!savedCompPids || !selectedComps) return true;
    return (
      savedCompPids.length === selectedComps.length &&
      savedCompPids.every((pid, index) => pid === selectedComps[index])
    );
  }, [savedCompPids, selectedComps]);
  // If this value is true, your comps are different.
  const isDifferent = useMemo(() => !isSame, [isSame]); // true if different, false if the same
  const gridRef = useRef(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const theme = useTheme();

  const dataGridStyles = useMemo(() => ({
    boxShadow: 2,
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '& .case-review--positive': {
        ...getBackgroundColor('#66bb6a', theme, 0.2),
        '&:hover': {
          ...getBackgroundColor('#66bb6a', theme, 0.4),
        },
        '&.Mui-selected': {
          ...getBackgroundColor('#66bb6a', theme, 0),
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            ...getBackgroundColor('#66bb6a', theme, 0.3),
          },
        },
      },
      '& .case-review--negative': {
        ...getBackgroundColor('#f44336', theme, 0.5),
        '&:hover': {
          ...getBackgroundColor('#f44336', theme, 0.6),
        },
        '&.Mui-selected': {
          ...getBackgroundColor('#f44336', theme, 0.3),
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            ...getBackgroundColor('#f44336', theme, 0.4),
          },
        },
      },
      '& .MuiDataGrid-cell': {
        border: 'none !important',
        borderTop: 'none !important',
        boxSizing: 'border-box',
        '&.Mui-selected': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          borderRight: `2px solid ${theme.palette.primary.main}`,
          borderBottom: 'none',
          backgroundColor: lighten('#66bb6a', 0.1),
        },
      },
      '& .edited': {
        color: '#facc15',
      },
  }), [theme]); // Memoized static styles

  // the visible lag that occurs when you click on the grid to swap (if user had selected other comps prev.)
  // force state update to clear out locally selected rows when header deselect is clicked.
  // useEffect(() => {
  //   // This is running on initial load, why is rowUpdate changing?
  //   // console.log('cleaning up')
  //   if(!!selectedComps){
  //   updateSelectedComps([]);
  //   }
  // }, [rowUpdate]);

  // If the user attempts to show it, it should override this
  // What columns are visible by default + loading in from localstorage if it exists
//   const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
//     const savedModel = localStorage.getItem('columnVisibilityModel');
//     return savedModel ? JSON.parse(savedModel) : {
//       PropertyAssessment: false,
//       Town: false,
//       ZipCode: false,
//       fullBaths: false,
//       halfBaths: false,
//       SDName: false,
//       View: false,
//       parcel_id: false,
//       MLSNumber: false,
//     };
//   });

//   // DIsplaly the view column if the subjct has a view
//   const memoizedColumnVisibilityModel = useMemo(() => {
//     // This programatically displays the view col
//     const updatedModel = { ...columnVisibilityModel };
//   if (subject?.View && subject.View !== "") {
//     updatedModel.View = true;
//   } 
//   return updatedModel;
// }, [subject, columnVisibilityModel]);

  // if a user changes column display, set in localstorage. - only runs once.
  useEffect(() => {
    // console.log('column viz changed.')
    localStorage.setItem('columnVisibilityModel', JSON.stringify(memoizedColumnVisibilityModel));
  }, [memoizedColumnVisibilityModel]);

  const forceToolbarUpdate = useCallback(() => {
    setToolbarKey(prevKey => prevKey + 1); // Change the key to force re-render
  }, []);

  const apiRef = useGridApiRef();
  
  // when you change comps, clear out the datagrid internal state for selected comp / edited inventory.
  // this doesn't happen for the subject but should.
  const handleApiOperations = useCallback((savedCompOverride=savedCompPids) => {
    // console.log('api operations firing to clear out the inventory update')
    if (apiRef.current) {
      const columns = apiRef.current.getAllColumns();
      const checkboxColumn = columns.find(col => col.field === 'actions');
      apiRef.current.setFilterModel({ items: [] });
      apiRef.current.setSortModel([]);
      // console.log(checkboxColumn)
      // console.log('its runnin')

      if (checkboxColumn) {
        // This doesn't work, it leaves the state hung and doesn't update properly
        // make it indeterminate if the length of savedCompOverride is > 0
        // checkboxColumn.indeterminate = savedCompPids.length > 0;
        // checkboxColumn.apiRef = apiRef;
        checkboxColumn.indeterminate = false;
        checkboxColumn.selectionArray = savedCompOverride;
        checkboxColumn.reOrderArray = [];
        apiRef.current.updateColumns([checkboxColumn]);
      }

      reOrderingRef.current = [];

      unsavedChangesRef.current = {
        originalSubject: {},
        unsavedRows: {},
        rowsBeforeChange: {},
        unsavedSubject: {},
      };


      // TEST IF YOU REMOVE THIS AGAIN!
      // This toolbar updat eis required for inventory updating to clear out old states.
      // forceToolbarUpdate() // i'm not sure this is necessary anymore? will have to test.
    }
  }, [apiRef, savedCompPids]);

  // added rowupdate into dependencies. Not sure if necessary.
  // Removed all the stuff here related to thee old issaving logic.
  useEffect(() => { // refactor to a callback so you stop getting issues w/ apiref current being null on load.
    cleaningFunction();
}, [comp, savedCompPids, view]);

  // Reset refs and internal grid states when user selects or re-orders a comp
  const cleaningFunction = useCallback(() => {
    // console.log('cleaning function')
    handleApiOperations();
    if(!!selectedComps){ // if its not empty,
      console.log('resetting to selectedCompss to false (empty)')
      updateSelectedComps(false);
      // updateSelectedComps(false);
    }
    handleCaseNotesCleanup()
    // caseNotesChangedRef.current = false;
    if(reordering){setReordering(false)}
  }, [handleApiOperations, selectedComps, reordering]);

  // Inventory update code:
  const handleProcessRowUpdate = useCallback((updatedRow, originalRow) => {
    // Use the stored original row if it exists, otherwise fallback to the actual current state
    const storedOriginalRow =
      unsavedChangesRef.current.rowsBeforeChange[updatedRow.id] ||
      apiRef.current.getRow(updatedRow.id) ||
      originalRow;
  
    const getChangedFields = (updated, original) => {
      return Object.keys(updated).reduce((acc, key) => {
        if (
          key !== 'PropertyInfo' &&
          key !== 'selected' &&
          key !== 'adj_price' &&
          updated[key] !== original[key]
        ) {
          acc[key] = updated[key];
        }
        return acc;
      }, {});
    };
  
    console.log('original', storedOriginalRow);
    const changedFields = getChangedFields(updatedRow, storedOriginalRow);
  
    const existingChanges = unsavedChangesRef.current.unsavedRows[updatedRow.id] || {};
  
    if (Object.keys(changedFields).length > 0) {
      // If no original row is stored, store it now
      if (!unsavedChangesRef.current.rowsBeforeChange[updatedRow.id]) {
        unsavedChangesRef.current.rowsBeforeChange[updatedRow.id] = { ...storedOriginalRow };
      }
  
      unsavedChangesRef.current.unsavedRows[updatedRow.id] = {
        ...existingChanges,
        ...changedFields,
        pid: updatedRow.PropertyInfo.parcel_id,
      };
    } else {
      delete unsavedChangesRef.current.unsavedRows[updatedRow.id];
      delete unsavedChangesRef.current.rowsBeforeChange[updatedRow.id];
    }
  
    apiRef.current.updateRows([updatedRow]); // Ensure the grid is updated
    forceToolbarUpdate();
    return updatedRow;
  }, [apiRef, forceToolbarUpdate]);
  
  
  // This save changes function is what causes the loading state to show up
  // Actually commit the inventory changes -- Need to test in production once deployed..
  const saveChanges = useCallback(async (additionalProps) => {
    // this function causes a MUITable rerender when the state updates, which causes the grid to rerender and not display the new user updated value.
    // Find some way to displayt he DB modal compoenent without causing a global rerender here
    try {
      // unsaved changes ref had no value.
      console.log('starting saving')
      // Persist updates in the database
      // setIsSaving(true); // this should maybe be changed to be a diff local
      setConfirmModal(true);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  }, []);

  // Pass this callback down to the subjtoolbar.
  const updateRefWithSubj = useCallback((newSubj,originalSubj) => {
    if(newSubj.Style === originalSubj.Style){ 
      // custom style handling, if the style is the same, delete it from the object. (this is necessary bc of downstream quirks)
      console.log('its the same!')
      delete newSubj.Style;
      // Also, if there's no keys left other than PID, set the whole value for subject and original to empty object
      if(Object.keys(newSubj).length === 1){
        unsavedChangesRef.current.unsavedSubject = {};
        unsavedChangesRef.current.originalSAubject = {};
        return;
      }
    }
    // Add in the unSavedSubject to the ref.
    unsavedChangesRef.current.unsavedSubject = newSubj;
    // add in the original subject to the ref.
    unsavedChangesRef.current.originalSubject = originalSubj;
    
    // this is necessary to have your htoolbar update for discard or update
    forceToolbarUpdate();
  }, []);

  // Will use this discard function later if the user doesn't commit their updates:
  const discardChanges = useCallback(() => {
    console.log(unsavedChangesRef.current)
    const latestRows = Object.values(unsavedChangesRef.current.rowsBeforeChange).map((row) =>
      // apiRef.current.getRow(row.id) || row // Fallback to ref if grid is missing the row
    row
    );
    apiRef.current.updateRows(latestRows);
    
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };
    forceToolbarUpdate();
    // use user selected comps if they exist:
    let savedCompOverride = savedCompPids;
    if(!!selectedComps){
      savedCompOverride = selectedComps;
    }

    handleApiOperations(savedCompOverride);
  }, [apiRef, handleApiOperations, selectedComps, startingRows]);


  // function to handle the custom checkbox click // had to add in rows as a dependency.
  // this has gotten unruly and should be broken into two different functions.
  const handleCellClick = useCallback((params) => {
    if (params.colDef.headerClassName === 'actions') {
      // for reordering, when you exit, then update the higher level state.
      if (reordering) { 
        // Then we will JUST be updating the order of your saved comps, do not change pinned rows.
        const selectedRowIds = params.colDef.reOrderArray || [];
        const allSelectedRows = params.colDef.selectionArray || [];
        
        console.log('prev. selected');
        console.log(selectedRowIds);
        
        console.log('allSelectedRows');
        console.log(allSelectedRows);
        
        // Determine the new list of selected rows
        const updatedSelectedRowIds = selectedRowIds.includes(params.row.parcel_id)
            ? selectedRowIds.filter(rowId => rowId !== params.row.parcel_id) // Remove if currently selected
            : [...selectedRowIds, params.row.parcel_id]; // Add if not currently selected
        
        // Use a Set to ensure uniqueness
        const finalRows = Array.from(new Set([...allSelectedRows, ...updatedSelectedRowIds]));
        console.log('final rows:', finalRows);
      
        console.log('new array');
        console.log(updatedSelectedRowIds);
      
        const parcelIds = new Array(updatedSelectedRowIds.length); // Initialize array to maintain order
        
        // Iterate over each row in originalRows
        const updatedRows = originalRows.map(row => {
          if (updatedSelectedRowIds.includes(row.parcel_id)) {
            // If the row is in the updated selection array, set isSelected to true and update selectedRowNumber
            parcelIds[updatedSelectedRowIds.indexOf(row.parcel_id)] = row.parcel_id;
            return {
              ...row,
              isSelected: true,
              selectedRowNumber: updatedSelectedRowIds.indexOf(row.parcel_id) + 1,
              reOrderArray: updatedSelectedRowIds
            };
          // add to parcel ID array at the index of new array
          } else if (selectedRowIds.includes(row.parcel_id)) {
            // If the row was previously selected but is now being removed, set isSelected to false
            return {
              ...row,
              isSelected: false,
              selectedRowNumber: null
            };
          }
          // Return unchanged rows
          return row;
        });
      
        // Apply the updates to the grid
        apiRef.current.updateRows(updatedRows);
      
        // Clear `parcelIds` if `updatedSelectedRowIds` is empty
        if (updatedSelectedRowIds.length === 0) {
          console.log('length is zero');
          const clearedRow = {
            ...params.row,
            isSelected: false,
            reOrderArray: null,
          };
          apiRef.current.updateRows([clearedRow]); // Update row in the grid
          params.colDef.reOrderArray = null;
          reOrderingRef.current = parcelIds;
          return;
        }
        
        // Set column definition properties
        params.colDef.reOrderArray = updatedSelectedRowIds;
        params.colDef.selectionArray = updatedSelectedRowIds;
        params.colDef.apiRef = apiRef;
        reOrderingRef.current = parcelIds;
        setUserReordered(true);
        
        console.log('Updated parcelIds:', parcelIds);
      }
      else{
      // normal selection fucntion)
      // Get the current selection array from the column
      const selectedRowIds = params.colDef.selectionArray || [];
      console.log(selectedRowIds)

      // console.log('currently selected rows')
      // console.log(selectedRowIds)

      // Determine if the row is currently selected
      const isCurrentlySelected = selectedRowIds.includes(params.row.parcel_id);

      // Find all rows that need to be updated based on the current selection state
      // const rowsToUpdate = rows.filter(row => selectedRowIds.includes(row.parcel_id) || row.parcel_id === params.row.parcel_id);
  
      // Toggle the selection array values
      const updatedSelectedRowIds = isCurrentlySelected
        ? selectedRowIds.filter(rowId => rowId !== params.row.parcel_id) // Remove if currently selected
        : [...selectedRowIds, params.row.parcel_id]; // Add if not currently selected

        // console.log('the new list')
        // console.log(updatedSelectedRowIds)
  
      const parcelIds = new Array(updatedSelectedRowIds.length); // Initialize array to maintain order
      // Update each row and insert in order based on updatedSelectedRowIds
      updatedSelectedRowIds.forEach((rowId, index) => {
        const row = startingRows.find(r => r.parcel_id === rowId); // Find the row based on rowId
        if (row) {
          const updatedRow = {
            ...row,
            isSelected: updatedSelectedRowIds.includes(row.parcel_id), // Set `isSelected` based on the new array
            rowIndexPosition: index + 1, // Use index + 1 to maintain the order
            selectionArray: updatedSelectedRowIds,
            selected: updatedSelectedRowIds.includes(row.parcel_id),
          };
  
          // Push the Parcel ID value into parcelIds at the correct index
          // console.log('index is:', index)
          // pinnedRows[index] = row
          apiRef.current.updateRows([updatedRow]); // Update row in the grid
        }
        parcelIds[index] = rowId;
      });

      // Update the column's selection array (this may be a legacy thing, idk if you still use. May use in deselect all in casereview.js)
      params.colDef.selectionArray = updatedSelectedRowIds;
      // Set indeterminate state if there are any selected rows
      // this should reallly be defined by if it was diff than original, not that its >0.
      // you should update this so it is indetermine if there are any
      params.colDef.indeterminate = updatedSelectedRowIds.length > 0; // Set indeterminate state if there are any selected rows
      params.colDef.apiRef = apiRef;
  
      // console.log(parcelIds)
      updateSelectedComps(parcelIds);
      // updateSelectedComps(parcelIds);
      }
    }

  }, [apiRef, rows, reordering]);
  

    React.useEffect(() => {
      const cellClickSubscription = apiRef.current.subscribeEvent('cellClick', handleCellClick);
    
      // Cleanup the event listener on unmount or when dependencies change
      return () => {
        cellClickSubscription(); // Unsubscribe from the event
      };
    }, [apiRef, handleCellClick]);


    // NEED TO UPDATE THIS TO BE ABLE TO ADD IN A NEW COMP! (and test more.)
    const addCompCallback = useCallback(
      (parcelId) => {
        console.log('Callback invoked to add comp:', parcelId);
    
        // Check if the parcelId is already pinned
        const isPinned = pinnedRows.top.find((row) => row.parcel_id === parcelId);
        if (isPinned) {
          console.log('Comp already pinned');
          toast.error('Comp already pinned');
          return;
        }
    
        // Get selected row IDs from the API reference
        const selectedRowIds = apiRef.current.getAllColumns()[0].selectionArray || [];
    
        // Check if the parcelId is already in selectedComps or selectedRowIds
        if (
          selectedRowIds.includes(parcelId) ||
          (Array.isArray(selectedComps) && selectedComps.includes(parcelId))
        ) {
          console.log('Comp already selected');
          toast.error('Comp already selected');
          return;
        }
    
        console.log('Comp not selected, proceeding to add:', parcelId);
    
        // Deep clone startingRows to avoid direct mutation
        const updatedStartingRows = cloneDeep(startingRows);
    
        // Find the new row in startingRows
        let newRow = updatedStartingRows.find((row) => row.parcel_id === parcelId);
    
        if (!newRow) {
          console.warn(`Row not found for Parcel ID ${parcelId}. Adding as a placeholder.`);
          if(getIsFetching){
            toast.error('You must wait until the area finishes running before adding a comp.')
            return
          }
    
          // Create a placeholder row
          newRow = {
            parcel_id: parcelId,
            id: updatedStartingRows.length,
            isSelected: true,
            rowIndexPosition: updatedStartingRows.length + 1,
            placeholder: true, // Optional flag to identify placeholders
          };
    
          // Add the placeholder row to startingRows
          updatedStartingRows.push(newRow);
          addCompNotInResponseCallback(newRow,comp,view);
        }
        // console.log(updatedStartingRows)
    
        // Update the row's properties for selection
        const updatedRow = {
          ...newRow,
          isSelected: true,
          rowIndexPosition: selectedRowIds.length + 1,
        };
    
        // Update startingRows with the modified row
        const rowIndex = updatedStartingRows.findIndex((row) => row.parcel_id === parcelId);
        console.log('the index is', rowIndex)
        updatedStartingRows[rowIndex] = updatedRow;

        console.log(updatedStartingRows)
        // this is the right value to update your rows:
    
        // Update the grid with the modified rows
        apiRef.current.updateRows(updatedStartingRows);
        // callBackRowUpdate

        // apiRef.current.setRowModels(updatedStartingRows);

    
        // Update the column's selection array
        const updateRows = [...selectedRowIds, parcelId];
        apiRef.current.getAllColumns()[0].selectionArray = updateRows;
    
        // Set indeterminate state if there are any selected rows
        apiRef.current.getAllColumns()[0].indeterminate = updateRows.length > 0;
    
        // Update the selectedComps state
        const updatedSelectedComps = Array.isArray(selectedComps)
          ? [...selectedComps, parcelId]
          : updateRows;
          
        updateSelectedComps(updatedSelectedComps);
    
        // Display a success toast
        toast.success(
          `Comp added to selected comps as #${updatedSelectedComps.length}. Save comps to keep.`
        );
      },
      [apiRef, pinnedRows, selectedComps, startingRows, updateSelectedComps, view, comp]
    );
    


    const getRowClassName = useCallback((params) => {
      // Determine the status class based on 'positive' or 'negative' status
      const statusClass = params.row.status === 'positive' ? 'positive' : 'negative';
      
      // Default to the row's selected value
      let isSelected = params.row.isSelected;
      
      // If `selectedComps` is provided, check if the row's parcel_id is included in `selectedComps`
      if (selectedComps) {
        isSelected = selectedComps.includes(params.row.PropertyInfo.parcel_id);
      } 
      // If `selectedComps` is an empty array, no rows should be selected
      else if (selectedComps && selectedComps.length === 0) {
        isSelected = false;
      }
      // Return the appropriate class based on `isSelected`
      return `${isSelected ? 'selected-comp' : `case-review--${statusClass}`}`;
    }, [selectedComps, savedCompPids]);
    

  // yellow coloring in grid for edited cells.
  const getCellClassName = useCallback((params) => {
    const rowId = params.row.id;
    const field = params.field;
    if(params.field !== 'pid'){
    const isCellEdited = !!unsavedChangesRef.current.unsavedRows[rowId]?.[field];
    // if(isCellEdited){
    // console.log(isCellEdited)
    // console.log(unsavedChangesRef.current)
    // }
    return isCellEdited ? 'edited' : '';
    }
  }, []);

  const mergedProps = useMemo(() => ({
    rows: rows,
    columns: headers,
    ...tableProps,
  }), [rows, headers, tableProps]);

  // User did not confirm to update database, close the modal and clear out the unsaved changes.
  const handleConfirmationClose = useCallback((responseObject=null,confirmed=false) => {
    
    if(confirmed){
    unsavedChangesRef.current.rowsBeforeChange = {};
    setConfirmModal(false);
    }
    else{
    console.log('canceling modal');
    if(responseObject){
      console.log('response object')
      // Here we should invoke the "update inventory" function. the fac tthat you don't have these seperated functions just returning vals is dumb.

      // 0th index = regular comps, 1st =optimized
      // here splice you rows per the response you got back.
      
    }
    // Close modal and call function to revert the grid back to the original state
    setConfirmModal(false);
    setIsSaving(false);
    discardChanges();
  }
  }, []);

  // only functions as local error handling would need to confirm the internal grid state is still correct. Haven't been able to get it to error to test.
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log('Error updating row:', error);
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };
  }, []);

  // This is incorrectly setting the saving state with a missing dependency before.
  const setIsSavingCallback = useCallback((isSaving) => {
    console.log('running issaving callback')
    // handleApiOperations([])
    setIsSaving(true);
  }, []);
  


  const handleToggleReordering = useCallback(() => {
    console.log('reordering toggle running')
    if(reOrderingRef.current.length>0){
      console.log('you already have a value, I think you need to set the user IDS value here.')
      console.log('reordering ref')
      console.log(reOrderingRef.current)

      // Why do we have this lower level selectedCompsstate
      updateSelectedComps(reOrderingRef.current);
      updateSelectedComps(reOrderingRef.current);
      // handleUpdateSelectedComps(reOrderingRef.current);
      // here you need to set the user update to those comps
      // this function sets the values equal to the normal 5 by clearing both out.
      // set the value equal to your ref
      // reOrderingRef.current = [];
      handleApiOperations(reOrderingRef.current);
    }
    // set steate
    setUserReordered(false)
    setReordering(!reordering);
  }, [reordering]);

  // This works exactly how you need it to. However, the initial saving state needs to exist.
  

  return (
    // if you have map enabled, then make this height smaller - done in parent.
    <div className='w-full h-full' ref={gridRef}>
      {/* display loading */}
      {confirmModal && (
        <DBConfirmModal
        confirmModal={confirmModal}
        onDBUpdateSuccess={handleProcessDBUpdate}
        // no reason to have this be two diff functions for closing approve vs disapprove.
        handleConfirmCancel={handleConfirmationClose}
        inventoryUpdates={unsavedChangesRef.current}
      />
      )
      }
      <DataGridContainer
      addCompCallback={addCompCallback}
      handleSetPropertyInfo={handleSetPropertyInfo}
      toolbarKey={toolbarKey}
      caseNotesChanged={caseNotesChanged}
      discardChanges={discardChanges}
      comps={originalRows}
      subject={subject}
      setIsSavingCallback={setIsSavingCallback}
      handleProcessRowUpdate={handleProcessRowUpdate}
      mergedProps={mergedProps}
      dataGridStyles={dataGridStyles}
      pinnedRows={pinnedRows}
      userUpdate={selectedComps}
      selectedRows={selectedComps&&selectedComps.length>0?selectedComps:savedCompPids}
      apiRef={apiRef}
      memoizedColumnVisibilityModel={memoizedColumnVisibilityModel}
      handleColumnVisibilityChange={handleColumnVisibilityChange}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      handleProcessRowUpdateError={handleProcessRowUpdateError}
      headers={headers}
      filteredHeaders={filteredHeaders}
      isDifferent={isDifferent}
      saveChanges={saveChanges}
      unsavedChangesRef={unsavedChangesRef}
      updateRefWithSubj={updateRefWithSubj}
      handleSaveComps={handleSaveComps}
      handleToggleReordering={handleToggleReordering}
      reordering={reordering}
      reOrderingRef={reOrderingRef}
      userReordered={userReordered}
      isSaving={isSaving}
      savedCompPids={savedCompPids}
      />
      
    </div>
  );
}
MuiTable.whyDidYouRender = true;
// export default React.memo(MuiTable, areEqual);
export default MuiTable;

// pass down a callback to this toolbar which updates the ref in the parent component.

