import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePersistedDataStore } from '../../store';
import { fields } from '../../../common/useStreamProcessorNew';
import BackToAnalysisButton from '../BackToAnalysisButton';
import MarketingInputs from './MarketingInputs'


// idk if we care about enforcing this minimum revenue amount.
const minimumRecentSaleRevenue = 100;
const ROWS_PER_PAGE = 100; // Adjust based on your needs
const CHUNK_SIZE = 1000;

const CustomToolbar = ({ MuniCode, isFetching, totalCases, currentCount, marketingResponse, minimumThreshold, highTaxCutoff }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          csvOptions={{
            fileName: `${MuniCode}_Marketing`,
            allColumns: true
          }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {isFetching && (
          <>
            <Typography variant="body2">
              Calculated {currentCount || 0} out of {totalCases || 'loading...'}
            </Typography>
            <CircularProgress size={16} />
          </>
        )}
      </div>
      <div>
        <GridToolbarQuickFilter />
      </div>
    </div>
  );
};

export default function MarketingTable({MuniCode, countyString, muniString, TaxYear}) {
  const getMarketingResponse = usePersistedDataStore((state) => state.marketingResponse);
  const getTotalCases = usePersistedDataStore((state) => state.totalCases);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [minimumThreshold, setMinimumThreshold] = useState(0);
  const [highTaxCutoff, setHighTaxCutoff] = useState(30000);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: ROWS_PER_PAGE,
    page: 0,
  });
  const [processedRows, setProcessedRows] = useState([]);

  const columns = useMemo(() => {
    return fields.map(({ field, headerName, width, type }) => ({
      field,
      headerName,
      width: width || 150,
      valueFormatter: type === 'numeric'
        ? (value) => {
            if (!value && value !== 0) return '';
            if (isNaN(Number(value))) return value;
            return Math.round(Number(value)).toLocaleString();
          }
        : type === 'percent'
        ? (value) => {
            if (!value && value !== 0) return '';
            if (isNaN(Number(value))) return value;
            return `${Number(value*100).toFixed(2)}%`;
          }
        : undefined,
    }));
  }, []);

  useMemo(() => {
    if (Object.keys(columnVisibilityModel).length === 0) {
      setColumnVisibilityModel(
        fields.reduce((acc, { field }) => {
          acc[field] = true;
          return acc;
        }, {})
      );
    }
  }, [fields, columnVisibilityModel]);

  // Process data in chunks when marketingResponse updates
  useEffect(() => {
    if (!getMarketingResponse) return;
    
    let currentChunk = 0;
    const totalChunks = Math.ceil(getMarketingResponse.length / CHUNK_SIZE);

    const processChunk = () => {
      const start = currentChunk * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, getMarketingResponse.length);
      
      const newRows = getMarketingResponse.slice(start, end).map(row => ({
        ...row,
        id: row.id || row.parcel_id // Ensure stable IDs
      }));

      setProcessedRows(prev => [...prev, ...newRows]);
      
      currentChunk++;
      if (currentChunk < totalChunks) {
        setTimeout(processChunk, 0);
      }
    };

    setProcessedRows([]); // Reset when new data comes in
    processChunk();
  }, [getMarketingResponse]);

  // Sort the processed rows by ProjectedAventineRevenue
  const sortedRows = useMemo(() => {
    return [...processedRows].sort((a, b) => 
      (b.ProjectedAventineRevenue || 0) - (a.ProjectedAventineRevenue || 0)
    );
  }, [processedRows]);

  const areaTitle = muniString !== "All" ? muniString : countyString;

  return (
    <div className="h-full flex flex-col ">
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        className="w-full"
      >
        <Box display="flex" width="100%" flexDirection="column" alignItems="center" my={!getIsFetching ? 2 : 0}>
          <Box 
            // set this to be max width xl
            display="flex" 
            width="100%" 
            alignItems="center" 
            justifyContent="space-between"
            sx={{ 
              minHeight: getIsFetching ? '64px' : 'auto',
              '& .MuiButtonBase-root': {
                alignSelf: 'center'
              }
            }}
          >
            <BackToAnalysisButton />
            <Typography variant="h4" sx={{textAlign: 'center' }}>
              {areaTitle} {TaxYear} Marketing
            </Typography>
            <Box sx={{ width: { xs: '40px', sm: '200px' } }} /> 
          </Box>
        </Box>

        <MarketingInputs 
          marketingResponse={getMarketingResponse}
          title={areaTitle} 
          minimumThreshold={minimumThreshold}
          setMinimumThreshold={setMinimumThreshold}
          minimumRecentSaleRevenue={minimumRecentSaleRevenue}
          highTaxCutoff={highTaxCutoff}
          setHighTaxCutoff={setHighTaxCutoff}
        />
      </Box>

      {/* Data Grid */}
      <DataGridPro
        rows={sortedRows}
        columns={columns}
        rowBuffer={5}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        rowHeight={52}
        style={{ 
          border: '1px solid rgba(224, 224, 224, 1)',
          height: '70vh' // Fixed height for virtualization
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        checkboxSelection
        loading={!getMarketingResponse || getMarketingResponse.length === 0}
        disableRowSelectionOnClick
        getRowId={(row) => row.id || row.parcel_id} // Ensure stable row IDs
        keepNonExistentRowsSelected
        slots={{ 
          toolbar: () => (
            <CustomToolbar 
              MuniCode={MuniCode} 
              isFetching={getIsFetching}
              totalCases={getTotalCases}
              currentCount={getMarketingResponse?.length}
              marketingResponse={getMarketingResponse}
              minimumThreshold={minimumThreshold}
              highTaxCutoff={highTaxCutoff}
            />
          ) 
        }}
      />
    </div>
  );
}
